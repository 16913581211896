import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import "./travelspiritmarketing.scss";
import kofferLabel from '../assets/images/labels/marketing-label.png';
import klantreviews from "../assets/images/marketingviews.svg";
import customersegment from "../assets/images/customersegment.svg";
import marketingspotler from "../assets/images/marketingspotler.svg";
import { Link } from "gatsby";

export default () => {
  return (
    <Layout>
      <SEO title="Travelspirit Marketing" urlParams="travelspiritmarketing" />
      <div>
        <div className="marketing-travel-page-full marketing-back-office-section-first">
          <div className="marketing-travel-page-container">
            <div className="marketing-back-office-content">
              <h6>Versterk klantrelaties</h6>
              <div className="marketing-back-office-wrap">
                <h1>MARKETING<br /></h1>
                <img alt="backoffice-koffer label" src={kofferLabel} />
              </div>
              <p>TravelSpirit biedt <strong>diverse marketingmogelijkheden</strong>, zoals een <strong>nieuwsbriefkoppeling</strong> om gemakkelijk met klanten te communiceren en inzicht te krijgen in aanmeldingen via het klanten dashboard. Daarnaast kunnen klanten eenvoudig <strong>reviews</strong> geven, bijvoorbeeld via TrustPilot, en is er een koppeling met Whooz voor nauwkeurige <strong>doelgroepsegmentatie</strong>.</p>
              <ul className="marketing-travel-page-container-ul">
                <li className="marketing-fullwidth">E-mailmarketing</li>
                <li className="marketing-fullwidth">Klantreviews</li>
                <li className="marketing-fullwidth">Klantsegmentatie</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="marketing-travel-page-full marketing-back-office-section-two" id="e-mailmarketing">
          <div className="marketing-travel-page-container">
            <div className="marketing-all-travel-common-box-main">
              <div className="marketing-all-travel-common-box-left">
                <div className="center"><img alt="Marketing  spotler" src={marketingspotler} /></div>
              </div>
              <div className="marketing-all-travel-common-box-right">
                <h3>E-mailmarketing</h3>
                <p>Gerichte online marketing campagnes. Met de nieuwsbrief mogelijkheden van TravelSpirit kun je (potentiële) <strong>klanten gericht mailen</strong>. Je stuurt b.v. de nieuwsbrief over die hele luxe reis aan alleen diegenen die het label 'Big spender' hebben, i.p.v. naar het hele bestand</p>
                <p>In de CRM Contacten cockpit zie je direct of de klant die je aan de telefoon hebt, is aangemeld voor de nieuwsbrief. Mocht dit niet het geval zijn, dan kun je de klant aanbieden de aanmelding alsnog (zelf) uit te voeren. Het aantal aanmelden zal hierdoor flink stijgen.</p>
                <p>Er zijn nog <strong>meer mogelijkheden</strong> wanneer je gebruik maakt van Spotler of MailChimp. Zo zie je welke nieuwsbrieven het contact geopend heeft, of kun je klanten die b.v. net geboekt hebben, uitsluiten van aanbiedingen.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="marketing-travel-page-full marketing-crm-office-section" id="customer-reviews">
          <div className="marketing-travel-page-container">
            <div className="marketing-all-travel-common-box-main">
              <div className="marketing-all-travel-common-box-left">
                <div className="center"><img alt="Marketing  Klantreviews" src={klantreviews} /></div>
              </div>
              <div className="marketing-all-travel-common-box-right">
                <h3>Klantreviews</h3>
                <p>Je kunt de klant vanuit de contacten cockpit uitnodigen om je <strong>bedrijf</strong> of <strong>diensten</strong> te <strong>waarderen</strong> met TrustPilot.</p>
                <p>Bij het openen van de cockpit zie je het <strong>aantal TrustPilot sterren</strong> direct in beeld en kun je teruglezen wat de klant heeft geschreven. In combinatie met bijv. de telefoonkoppeling, kun je dus accuraat inspelen op hetgeen je ter plekke voor je hebt.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="marketing-travel-page-full marketing-telephonie-section" id="marketing-segmentation">
          <div className="marketing-travel-page-container">
            <div className="marketing-all-travel-common-box-main">
              <div className="marketing-all-travel-common-box-left">
                <div className="center"><img alt="Marketing  Customer Segment" src={customersegment} /></div>
              </div>
              <div className="marketing-all-travel-common-box-right">
                <h3>Klantsegmentatie</h3>
                <p>De Nederlandse samenleving is continu in beweging. Om het meest nauwkeurige beeld te blijven schetsen van de Nederlandse consument lanceerde Whooz in 2019 een compleet nieuwe generatie van hun segmentatie.</p>
                <p>Met hun segmentatiemodel Whize™ <strong>classificeren</strong> zij al deze <strong>huishoudens</strong> in elf hoofd- en 59 subsegmenten. Met behulp van de TravelSpirit Whize <strong>verrijk</strong> je eenvoudig <strong>alle contacten</strong> in het TravelSpirit CRM met deze informatie. Je maakt <strong>direct inzichtelijk</strong> welke <strong>type klanten</strong> het beste bij jou passen. Dit geeft zeer <strong>waardevolle input</strong> om jouw sales-en marketing inspanningen effectief in te zetten.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="marketing-travel-page-full marketing-kost-section">
          <div className="marketing-travel-page-container">
            <div className="marketing-kost-section-content">
              <h2>Wat kost de Marketing?</h2>
              <p>Voor elke type reisorganisatie bieden wij een passend abonnement inclusief de Marketing</p>
              <Link key='markting-pricing' to={`/pricing`}>
                Bekijk abonnementen <span>&gt;</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="marketing-travel-page-full marketing-benieuwd-section">
          <div className="marketing-travel-page-container">
            <div className="marketing-benieuwd-section-content">
              <h2>Benieuwd wat TravelSpirit voor jou kan betekenen?</h2>
              <p>Wij gaan graag met je in gesprek om de mogelijkheden te bespreken om je verder te helpen.</p>
              <div className="marketing-benieuwd-btn">
                <div className="marketing-benieuwd-btn-item">
                    <Link key='marketing-demo' to={`/demo`} className="btn btn-primary marketing-demo-implement">
                        Demo inplannen
                    </Link>
                    <span className="marketing-benieuwd-btn-item-text">Online sessie van 30 minuten</span>
                </div>
                <div className="marketing-benieuwd-btn-item">
                    <Link key='marketing-verkennend' to={`/demo-afspraak`} className="btn btn-primary verkennend-implement">
                        Verkennend gesprek
                    </Link>
                    <span className="marketing-benieuwd-btn-item-text">Stel online al jouw vragen</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
